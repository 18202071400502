import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';

import naja from 'naja';
document.addEventListener('DOMContentLoaded', () => naja.initialize({
    history: false
}));
import netteForms from '../../vendor/nette/forms/src/assets/netteForms'
naja.formsHandler.netteForms = netteForms;
